<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="login-box">
      <h3 class="title">{{ $t(114,{appName:configObj.appName}) }}</h3>
      <div class="tip" v-if="configObj.accountType==='0'">{{ $t(115) }}</div>
      <div class="tab" v-if="configObj.accountType==='0'">
        <span class="item" :class="{active: form.type==='EMAIL'}" @click="form.type='EMAIL'">{{ $t(101) }}</span>
        <span class="item" :class="{active: form.type==='MOBILE'}" @click="form.type='MOBILE'">{{ $t(102) }}</span>
      </div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form">
        <div class="item" v-show="form.type==='EMAIL'">
          <div class="label mb5">{{ $t(101) }}</div>
          <el-form-item prop="email">
            <el-input v-model="form.email">
            </el-input>
          </el-form-item>
        </div>
        <div class="item" v-show="form.type==='MOBILE'">
          <div class="label mb5">{{ $t(102) }}</div>
          <el-form-item
            prop="mobile">
              <el-input v-model="form.mobile">
              <el-select slot="prepend" v-model="form.areaCode" class="code-select" filterable popper-class="bfff">
                <el-option
                  v-for="item in countryList"
                  :key="item.countryCode"
                  :label="'+'+item.areaCode"
                  filterable
                  :value="item.areaCode"
                >{{ item.englishName+'+'+item.areaCode }}</el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>
        <div class="item" v-if="configObj.isOpenGlobalVerifyCode==='1'&&configObj.isOpenRegVerifyCode==='1'">
          <div class="label mb5">{{ $t(122) }}</div>
          <el-form-item
            prop="verifyCode">
              <el-input v-model="form.verifyCode">
                <el-button slot="append" :disabled="text!==sendText" type="text" @click="sendCode">
                  <span :class="{'countDown': sendText !== text}">{{ sendText }}</span>
                </el-button>
            </el-input>
          </el-form-item>
        </div>
        <div>
          <div class="label mb5">{{ $t(105) }}</div>
          <el-form-item
            prop="password">
              <el-input type="password" v-model="form.password" show-password></el-input>  
          </el-form-item>
        </div>
        <div v-if="configObj.isShowInviteCode==='1'">
          <div class="label mb5 cursor flex align-center" @click="inviteCodeClick">{{ $t(isNOWDEX ? '116x' :116) }} <span v-if="configObj.isNeedRealInviteCode!=1">({{ $t(208) }})</span><i class="icon cursor" v-if="configObj.isNeedRealInviteCode!=1" :class="{'el-icon-caret-bottom': !inviteCodeExpand,'el-icon-caret-top':inviteCodeExpand}"></i> </div>
          <el-form-item
            v-show="inviteCodeExpand"
            prop="inviteCode">
              <el-input v-model="form.inviteCode"></el-input>  
          </el-form-item>
        </div>
        <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(95) }}</el-button></div>
        <span type="text">{{ $t(121) }}</span>
        <el-button type="text" @click="$router.push('/login')">{{ $t(94) }}</el-button>
      </el-form>
      <div class="corpyrights">© 2017 - 2021 {{ configObj.appName }} All rights reserved</div>
    </div>
  </div>
</template>

<script>
import loginMixins from '@/mixins/login.js'
import SimpleHeader from '@/components/SimpleHeader'
import { register, getMobileCodeWithoutToken, getEmailCodeWithoutToken } from '@/api'
import utils from '@/utils/misc'
export default {
  name: 'Register',
  components: { SimpleHeader },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        email: '',
        type: window.configObj.accountType === '2'?  'EMAIL' : 'MOBILE',
        mobile: '',
        areaCode: window.configObj.defaultAreaCode,
        password: '',
        inviteCode: '',
        verifyCode: '',

      },
      inviteCodeExpand: window.configObj.isNeedRealInviteCode === '1',
      sendText: '',
      text: this.$t('123'),
      timer: null
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
        ...this.commonRules,
        inviteCode:[{
          validator: (rule,value,callback) => {
            if (!value && window.configObj.isNeedRealInviteCode === '1') {
              return callback(new Error(this.$t(this.isNOWDEX ? '308x' : 308)))
            }
            callback()
          }
        }]
      }
    },
    isNOWDEX() {
      return utils.getLs('agentIdentify') === 'c8b090c0-7b79-4c77-b7df-9b3cb149fbc7'
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  beforeMount() {
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
    this.sendText = utils.getLs('sendText') || this.text
    this.setTimer()
  },
  methods: {
    inviteCodeClick() {
      if (window.configObj.isNeedRealInviteCode === '1') return
      this.inviteCodeExpand = !this.inviteCodeExpand
    },
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const obj = {...this.form}
          if (obj.verifyCode === '') { // 验证码没开启默认传：123456
            obj.verifyCode = '123456'
          }
          register(obj).then(res => {
            this.showSuccessMsg(this.$t(307))
            this.$router.push({name: 'Login', query: { mobile:obj.type==='MOBILE'?obj.mobile:undefined,areaCode:obj.type==='MOBILE'?obj.areaCode:undefined,email: obj.type==='EMAIL'?obj.email:undefined,}})
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    setTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (isNaN(this.sendText)) return
        if (this.sendText <= 1) {
          this.sendText = this.text
          clearInterval(this.timer)
          utils.removeLs('sendText')
        } else {
          this.sendText--
          utils.setLs('sendText', this.sendText)
        }
      }, 1000)
    },
    sendCode() {
      if (this.loading) return
      const key = this.form.type === 'MOBILE'? 'mobile':'email'
      this.$refs.form.validateField(key, (error) => {
        if (!error) {
          const obj = {
            scene: 'REGISTER',
            [key]: this.form[key]
          }
          if (this.form.type === 'MOBILE') {
            obj.areaCode = this.form.areaCode
          }
          this.loading = true
          const api = this.form.type === 'EMAIL' ? getEmailCodeWithoutToken: getMobileCodeWithoutToken
          api(obj).then(() => {
            this.sendText = 60
            this.setTimer()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login-box{
    color: #1E2329;
    width: 400px;
    margin: 10vh auto;
    .title{
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .tab{
      margin: 40px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;

        &.active{
          background-color: #dce1d9;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
  }
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
  .countDown{
    color: #C0C4CC;
  }
</style>